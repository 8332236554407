#header_first{
  position: absolute;
  top: 0;
  left: 0;
  min-height: 60px;
  width: 100%;
  height: 9.1vw;
  pointer-events: none;
}
.nav_header{
  position: fixed !important;
  background: #e9e9e9;
  top: 9.1vw;
  left: 0;
  width: 100%;
  padding: 10px 0;
  z-index: 100;
  .navbar-brand{
    position: absolute;
    font-size: 18px;
    cursor: pointer;
    @media screen and (max-width: 991px){
      position: relative;
    }
  }
  #logo{
    mix-blend-mode: multiply;
    width: 70px;
    height: 100%;
    max-height: 50px;
    position: absolute;
    top: 5px;
    left: 75px;
    cursor: pointer;
  }
  a{
    font-size: 17px;
  }
}