.full_video{
  margin-top: 61px;
  width: 100%;
}
#about_us{
  position: relative;
  margin-top: 150px;
  .col-12{
    background-color: rgba(62, 78, 19, 0.497);
    margin-top: 50%;
    color: #fff;
    transform: translateY(-100%);
  }
  .back_image{
    position: absolute;
    display: flex;
    flex-wrap: wrap;
    top: 0;
    left: 0;
    z-index: -1;
    video,img{
      width: 50%;
      max-height: 750px;
      height: 100%;
      &:nth-child(3){
        margin-top: -145px;
      }
      &:nth-child(4){
        max-height: 605px;
      }
    }
  }
  @media screen and (max-width: 1200px) {
    margin-top: 100px;
    margin-bottom: 50px;
    .col-12{
      background-color: transparent;
      margin-top: 0;
      color: #000;
      transform: translateY(0);
    }
    .back_image{
      display: none;
    }
  }
}
.title{
  font-size: 24px;
  font-weight: 700;
}
#contact_us{
  background-color: rgba(62, 78, 19, 0.497);
  color: #fff;
  transform: translateY(-143.8%);
  .icon{
    font-size: 48px;
    color: #fff;
  }
  a{
    color: #fff;
    text-decoration: none;
  }
  @media screen and (max-width: 1200px) {
    background-color: transparent;
    color: #000;
    transform: translateY(0);
    .icon{
      color: blue;
    }
    a{
      color: #000;
    }
  }
}
.public_video{
  iframe{
    width: 100%;
    height: 603px;
    @media screen and (max-width: 1400px) {
      height: 520px;
    }
    @media screen and (max-width: 1200px) {
      height: 435px;
    }
    @media screen and (max-width: 992px) {
      height: 320px;
    }
    @media screen and (max-width: 768px) {
      height: 290px;
    }
    @media screen and (max-width: 576px) {
      height: 258px;
    }
  }
}
.public_photo{
  iframe{
    width: 100%;
    height: 850px;
  }
}
.gallery{
  margin-top: 50px;
}
// .public_help{
//   iframe{
//     width: 100%;
//     height: 238px;
//   }
// }
#footer{
  background-color: silver;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
  margin-top: 30px;
  padding: 15px;
  color: #000;
  font-family: serif;
  a{
    color: blue;
    font-weight: bold;
    text-decoration: none;
  }
  @media screen and (max-width:430px) {
    font-size: 90%;
  }
  @media screen and (max-width:375.2px) {
    font-size: 80%;
  }
}